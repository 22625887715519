<template lang="pug">
.w-100
  navs

  .content-height
    .brands-bg
      .back-opacy
        h3.font-weight-bold.text-white.mb-0.text-shadow Marcas

    .section
      b-container
        h3.font-weight-bold.mb-3.text-center Descarga nuestros catálogos
        p.text-secondary.text-center.mb-5 Aquí puedes descargar todos los catálogos disponibles
        b-row
          b-col.text-center.mb-3.mb-md-5(v-for='(catalog, index) in catalogs', :key='index', md='4')
            b-img.image.mb-3(:src='require(`@/assets/images/brands/${catalog.icon}`)', height='55')
            div
              b-button(variant='primary', size='sm') Ver catálogo

    contact-form

  footer-component
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import FooterComponent from '@/components/Footer.vue'
import Navs from '@/components/Navs.vue'

export default {
  components: { ContactForm, FooterComponent, Navs },
  data() {
    return {
      catalogs: [
        {
          icon: 'appleton.png',
          url: '',
        },
        {
          icon: 'conteesa.png',
          url: '',
        },
        {
          icon: 'emerson.png',
          url: '',
        },
        {
          icon: 'hubbel.png',
          url: '',
        },
        {
          icon: 'luminance.png',
          url: '',
        },
        {
          icon: 'nita.png',
          url: '',
        },
        {
          icon: 'philco.png',
          url: '',
        },
        {
          icon: 'syltech.png',
          url: '',
        },
        {
          icon: 'tork.png',
          url: '',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.image {
  background-color: #292d78;
  border-radius: 10px;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.back-opacy {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.brands-bg {
  width: 100%;
  height: 130px;
  background-image: url('~@/assets/images/brands-bg.png');
  background-position: center;
  background-size: cover;
}

.content-height {
  margin-top: 106px;
  min-height: calc(100vh - 106px - 177px);

  @include media-breakpoint-up(md) {
    margin-top: 112px;
    min-height: calc(100vh - 112px - 57.31px);
  }
}
</style>